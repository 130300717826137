import styled from 'styled-components';

export const DivWrapper = styled.div``;

export const MessageText = styled.div`
  text-align: center;
`;

export const NameText = styled.div`
  text-align: center;
  font-size: medium;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.blue};
`;
