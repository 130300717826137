import { Moment } from 'moment';
import { FormInstance } from 'components/Form';
import { UploadProps } from 'antd/es/upload/interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { EditorState } from 'react-draft-wysiwyg';
import React from 'react';
import { NamePath } from 'rc-field-form/es/interface';
import { TagItem } from 'components/TagCollection/helper';

export const REGEXP = {
  ALPHABETICAL: /^([a-zA-Z]+)$/,
  SPECIAL_CHARACTERS_AND_NUMBERS: /^(?!.*[a-zA-Z]+)$/,
  ALPHANUMERIC: /^([a-zA-Z0-9]+)$/,
  PASSWORD: /^((?=.*\d)(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,16})$/,
  NEW_PASSWORD: /^((?=.*\d)(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,16})$/,
  ALPHANUMERIC_SPACE: /^([a-zA-Z0-9-\s]+)$/,
  EMAIL:
    /^(?!\.)(?!.*\.\.)(?!.*\.@)(?!-)(?!.*--)(?!.*-@)(?!.*__)[\w-_.]{1,180}@([\w-]+\.)+[\w-]{2,4}$/,
  AT_LEAST_ONE_LETTER_EMAIL: /^.*[a-zA-z0-9].*(?=@).*$/,
  TIME_FORMAT: /^([0-9][0-9]):[0-5][0-9]$/,
  NUMERIC: /^(-?)(0|([1-9][0-9]*))(\.[0-9]+)?$/,
  TWO_DECIMALS: /^(-?)([0-9]*(\.[0-9]{0,2})?)$/,
  PHONE_START: /^[1-9][0-9]*$/,
};

export interface FormFields {
  [key: string]: string | null | moment.Moment | Blob | number;
}

export interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

export interface Options<ValueType = string | number> {
  value: ValueType;
  name: string;
}

export interface DateConfig {
  dateFormat: string | undefined;
  defaultPickerValue: moment.Moment | undefined;
  disabledDate: (current: moment.Moment) => boolean;
}
export interface AreaConfig {
  minRows: number | undefined;
  maxRows: number | undefined;
}

export interface SwitchConfig {
  defaultChecked?: boolean;
  checkedChildren?: string;
  unCheckedChildren?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

export enum FormSelectMode {
  multiple = 'multiple',
  tags = 'tags',
}

export type TypeFormItemType =
  | 'text'
  | 'skills'
  | 'section'
  | 'password'
  | 'switch'
  | 'email'
  | 'select'
  | 'textArea'
  | 'date'
  | 'phone'
  | 'checkbox'
  | 'upload'
  | 'editor'
  | 'number'
  | 'hidden'
  | 'input'
  | 'autocomplete'
  | 'tagCollection';

export enum FormItemType {
  text = 'text',
  skills = 'skills',
  section = 'section',
  password = 'password',
  switch = 'switch',
  email = 'email',
  select = 'select',
  textArea = 'textArea',
  date = 'date',
  phone = 'phone',
  checkbox = 'checkbox',
  upload = 'upload',
  editor = 'editor',
  number = 'number',
  hidden = 'hidden',
  input = 'input',
  autocomplete = 'autocomplete',
  tagCollection = 'tagCollection',
}

export interface AutoCompleteInterface {
  key: string;
  value: string;
}

export interface FormItemConfig {
  tagCollectionConfig?: {
    items: TagItem[];
    addButtonText?: string;
    popoverTitle?: string;
    onChange?: (props: { tags: TagItem[] }) => void;
    onSearch?: (props: { searchValue: TagItem[] }) => void;
    form: FormInstance;
    isEditable?: boolean;
    options?: {
      value: string;
      label: string;
    }[];
  };
  name: string;
  mode?: FormSelectMode;
  label: string;
  hideLabel?: boolean;
  type: TypeFormItemType;
  value?: string;
  min?: number;
  max?: number;
  placeholder?: string;
  helper?: React.ReactNode;
  dependencies?: NamePath[];
  options?: Options<any>[];
  disabled?: boolean;
  optional?: boolean;
  trimStart?: boolean;
  minLength?: number;
  maxLength?: number;
  form?: FormInstance;
  rules?: any[];
  isSection?: boolean;
  dateConfig?: DateConfig;
  areaConfig?: AreaConfig;
  initialValue?: string;
  hidden?: boolean;
  uploadConfig?: UploadProps;
  switchConfig?: SwitchConfig;
  checked?: string;
  allowClear?: boolean;
  onBlur?: () => void;
  onSearch?: (value: string) => void | Promise<void>;
  editorState?: EditorState;
  onEditorStateChange?: (editorState: EditorState) => void;
  disabledDate?:
    | ((date: Moment, form: FormInstance) => boolean)
    | ((form: FormInstance) => boolean);
  onChangeHandler?: (value: any) => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: (event: any) => void;
  onDateChangeHandler?:
    | ((value: Moment | null, dateString: string) => void)
    | undefined;
  onCheckboxChangeHandler?: (e: CheckboxChangeEvent) => void | undefined;
  disabledArray?:
    | []
    | {
        projectId: number;
        hasHoursReported: boolean | undefined;
      }[];
}

export const sortOptions = (options: Options[]): Options[] => {
  return options.sort((optionA, optionB) => {
    const nameA = optionA.name;
    const nameB = optionB.name;

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const getOptions = (
  items: { id: number | string; name: string }[],
  stringified = true
): Options[] => {
  const options: Options[] = items.map((item) => {
    return {
      value: stringified ? item.id.toString() : item.id,
      name: item.name,
    } as Options;
  });
  return sortOptions(options);
};
