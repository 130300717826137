import React, { FC, memo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Modal from 'components/Modal';
import { Button } from 'components/Button';
import { showNotification } from 'shared/utils/common.utils';
import { DivWrapper, MessageText, NameText } from './styles';

type buttonType = 'primary' | 'default';

interface Props {
  title?: string;
  name?: string;
  buttonText?: string;
  message: string;
  path?: string;
  buttonType?: buttonType;
  showModal?: boolean;
  disabled?: boolean;
  onConfirm?: Function;
  onConfirmAsync?: () => Promise<any>;
  component?: React.ReactNode;
}

const ConfirmationModal: FC<Props> = ({
  title,
  name,
  buttonText,
  message,
  path,
  buttonType,
  showModal,
  onConfirm,
  onConfirmAsync,
  component,
  disabled,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const history = useHistory();

  const cancelHandler = () => {
    setOpenModal(false);
    setConfirmLoading(false);
  };

  const okHandler = useCallback(() => {
    if (onConfirm) {
      onConfirm();
      cancelHandler();
    }
    if (onConfirmAsync) {
      setConfirmLoading(true);
      onConfirmAsync()
        .then(() => {
          cancelHandler();
        })
        .catch(() => {
          showNotification(t, null, true);
          cancelHandler();
        });
    }
    if (path) {
      history.push(path);
    }
  }, [history, path, onConfirm, onConfirmAsync, t]);

  const buttonClickHandler = useCallback(() => {
    if (!showModal) {
      okHandler();
    } else {
      setOpenModal(true);
    }
  }, [okHandler, showModal]);

  return (
    <>
      <DivWrapper
        onClick={() => {
          if (!disabled) {
            buttonClickHandler();
          }
        }}
      >
        {component}
      </DivWrapper>
      {!component && (
        <Button
          type={buttonType || 'default'}
          onClick={buttonClickHandler}
          disabled={disabled}
        >
          {t(buttonText || 'common.cancel')}
        </Button>
      )}
      {openModal && (
        <Modal
          open={!showModal || true}
          centered
          title={t(title || 'common.confirm')}
          okType="primary"
          okText={t('common.confirm')}
          confirmLoading={confirmLoading}
          onCancel={cancelHandler}
          cancelText={t('common.cancel')}
          onOk={okHandler}
        >
          {name && <NameText>{name}</NameText>}
          <MessageText>{t(message)}</MessageText>
        </Modal>
      )}
    </>
  );
};

ConfirmationModal.defaultProps = {
  title: 'common.confirm',
  name: '',
  buttonText: 'common.cancel',
  path: '',
  buttonType: 'default',
  showModal: true,
  onConfirm: undefined,
  onConfirmAsync: undefined,
  component: undefined,
  disabled: false,
};

export default memo(ConfirmationModal);
