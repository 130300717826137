import React, { FC, PropsWithRef, ComponentType, memo, useMemo } from 'react';
import { TableProps as AntTableProps, Typography } from 'antd';
import { TableStyled } from './styles';

export type TableProps = AntTableProps<any>;

const Table: FC<PropsWithRef<TableProps>> = ({
  ...restProps
}: PropsWithRef<TableProps>): JSX.Element => {
  const columns = useMemo(
    () =>
      restProps.columns?.map((column) => {
        return {
          ...column,
          title: <Typography.Text strong>{column.title}</Typography.Text>,
        };
      }),
    [restProps.columns]
  );
  return (
    <>
      <TableStyled<ComponentType<any>> {...{ ...restProps, columns }} />
    </>
  );
};

export default memo(Table);
