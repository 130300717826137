export type State<Columns> = [
  filter: Columns,
  setFilter: React.Dispatch<React.SetStateAction<Columns>>
];

export interface PaginationType {
  page: number;
  pageSize: number;
  total: number;
}

export const ACTIVE = 'active';
