import React from 'react';
import { ListProps, List as AntList } from 'antd';

const List = <T extends unknown>({ ...props }: ListProps<T>): JSX.Element => {
  return (
    <>
      <AntList {...props} />
    </>
  );
};

export default List;
