import React from 'react';
import { CommentProps } from 'antd';
import { CommentStyled } from './styles';

const Comment = ({ children, ...restProps }: CommentProps) => {
  return (
    <>
      <CommentStyled {...restProps}>{children}</CommentStyled>
    </>
  );
};

export default Comment;
