import React, { PropsWithChildren } from 'react';
import { ButtonProps as AntdButtonProps } from 'antd';
import { theme } from 'theme';
import { RefreshButtonStyled } from './styles';

type ButtonSize = 'large' | 'middle' | 'small';
export type ButtonProps = AntdButtonProps;

interface Props extends ButtonProps {
  width?: string;
  height?: string;
  margin?: string;
  size?: ButtonSize;
}

export function RefreshButton({
  children,
  ...restProps
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <>
      <RefreshButtonStyled {...restProps}>{children}</RefreshButtonStyled>
    </>
  );
}

RefreshButton.defaultProps = {
  width: theme.buttonSize.width,
  height: theme.buttonSize.height,
  margin: '',
  size: 'middle',
};
