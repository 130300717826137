import React, { useState, useCallback, useEffect } from 'react';
import { selectUserFullName } from 'store/auth/auth.selectors';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Comment from 'components/Comments';
import { FormInstance } from 'antd';
import AddComment from './AddComment';
import CommentList from './CommentList';
import CommentsAvatar from './CommentsAvatar';
import { CommentItem } from '../helpers';

interface Props {
  setDisabledSendButton?: React.Dispatch<React.SetStateAction<boolean>>;
  commentsData: string;
  loading: boolean;
  resetState?: boolean;
  clearComment?: boolean;
  saveComment?: boolean;
  canAddComment?: boolean;
  sendButton?: boolean;
  disableInput?: boolean;
  readonly?: boolean;
  required?: boolean;
  customLabels?: { singular: string; plural: string };
  customPlaceholder?: string;
  onChangeTypeComment?: (comment: string, isAValidComment: boolean) => void;
  handleCancel?: () => void;
  cancelButton?: boolean;
  form?: FormInstance<any>;
}

interface FormProps {
  formComment: true;
  onAddComment?: (comments: string) => Promise<boolean>;
}

interface NoFormProps {
  formComment?: false;
  onAddComment: (comments: string) => Promise<boolean>;
}

type DynamicProps = Props & (FormProps | NoFormProps);

const CommentsSection = ({
  setDisabledSendButton,
  onAddComment,
  commentsData,
  loading,
  resetState = false,
  canAddComment,
  sendButton,
  disableInput,
  formComment,
  clearComment,
  saveComment,
  readonly,
  required,
  customLabels,
  customPlaceholder,
  onChangeTypeComment,
  handleCancel,
  cancelButton,
  form,
}: DynamicProps) => {
  const userName = useSelector(selectUserFullName);
  const [comments, setComments] = useState<CommentItem[]>([]);

  useEffect(() => {
    const commentsDataJson = JSON.parse(commentsData || '[]');
    setComments(commentsDataJson);
  }, [commentsData]);

  const handleAddComment = useCallback(
    async (newComment: string) => {
      if (!newComment) {
        return;
      }

      const newCommentItem = {
        author: userName,
        content: newComment,
        datetime: moment().toISOString(),
      };
      const newComments = [newCommentItem, ...comments];
      if (onAddComment) {
        const addCommentResult = await onAddComment(
          JSON.stringify(newComments)
        );
        if (addCommentResult) {
          setComments(newComments);
        }
      }
    },
    [comments, onAddComment, userName]
  );

  return (
    <>
      {comments.length > 0 && (
        <CommentList
          comments={comments}
          loading={loading}
          resetState={resetState}
          customLabels={customLabels}
        />
      )}
      {!readonly && (
        <Comment
          avatar={<CommentsAvatar />}
          content={
            <AddComment
              handleCancel={handleCancel}
              cancelButton={cancelButton}
              setDisabledSendButton={setDisabledSendButton}
              saveComment={saveComment}
              onAddComment={handleAddComment}
              isSubmitting={loading}
              initState={resetState}
              clearComment={clearComment}
              canAddComment={canAddComment}
              sendButton={sendButton}
              disableInput={disableInput}
              customPlaceholder={customPlaceholder}
              required={required}
              formComment={formComment}
              onChangeTypeComment={onChangeTypeComment}
              formInstance={form}
            />
          }
        />
      )}
    </>
  );
};

CommentsSection.defaultProps = {
  setDisabledSendButton: undefined,
  canAddComment: false,
  sendButton: true,
  disableInput: false,
  readonly: false,
  required: true,
  clearComment: false,
  saveComment: false,
  customLabels: undefined,
  customPlaceholder: '',
  onChangeTypeComment: undefined,
  handleCancel: undefined,
  cancelButton: false,
  resetState: false,
  form: undefined,
};

export default CommentsSection;
