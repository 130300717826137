import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import Form, { useForm } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd';
import {
  AUTO_SIZE_CONFIG,
  getTextAreaRules,
  MAX_COMMENT_LENGTH,
  MIN_CHARS,
} from './helpers';
import { ButtonWrapper } from './styles';

interface Props {
  setDisabledSendButton?: React.Dispatch<React.SetStateAction<boolean>>;
  onAddComment: (newComment: string) => void;
  isSubmitting: boolean;
  initState: boolean;
  clearComment?: boolean;
  saveComment?: boolean;
  canAddComment?: boolean;
  sendButton?: boolean;
  disableInput?: boolean;
  formComment?: boolean;
  required?: boolean;
  customPlaceholder?: string;
  onChangeTypeComment?: Function;
  handleCancel?: () => void;
  cancelButton?: boolean;
  formInstance?: FormInstance<any>;
}

const AddComment = ({
  setDisabledSendButton,
  onAddComment,
  isSubmitting,
  initState,
  canAddComment,
  sendButton,
  disableInput,
  saveComment,
  required,
  clearComment,
  formComment,
  customPlaceholder,
  onChangeTypeComment,
  handleCancel,
  cancelButton,
  formInstance,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [localForm] = useForm();
  const [comment, setComment] = useState('');
  const form = useMemo(
    () => formInstance || localForm,
    [formInstance, localForm]
  );

  const handleValuesChange = useCallback(
    (values: any) => {
      setComment(values.comment);
      setTimeout(() => {
        if (setDisabledSendButton) {
          if (!form.getFieldError('comment').length) {
            setDisabledSendButton(false);
          } else {
            setDisabledSendButton(true);
          }
        }
      }, 0);
    },
    [form, setDisabledSendButton]
  );

  const handleClick = useCallback(() => {
    if (!form.getFieldError('comment').length) {
      onAddComment(comment);
      form.resetFields();
      setComment('');
    }
  }, [onAddComment, comment, form]);

  const rules = getTextAreaRules(t, required);

  useEffect(() => {
    if (saveComment) {
      handleClick();
    }
  }, [saveComment, handleClick]);

  useEffect(() => {
    form.resetFields();
    setComment('');
  }, [clearComment, form]);

  useEffect(() => {
    if (initState) {
      form.resetFields();
      setComment('');
    }
    if (canAddComment) {
      handleClick();
    }
  }, [form, initState, canAddComment, handleClick]);

  const onChangeInputValue = (event: any) => {
    setTimeout(() => {
      const inputValue = event.target.value;
      const commentValue = inputValue ? inputValue.trimStart() : '';
      setComment(commentValue || '');
      form.setFieldValue('comment', commentValue);
      if (onChangeTypeComment) {
        onChangeTypeComment(
          commentValue,
          !form.getFieldsError().filter(({ errors }) => errors.length).length
        );
      }
    }, 0);
  };

  return (
    <>
      <Form form={form} onValuesChange={handleValuesChange}>
        <Form.Item name="comment" rules={rules}>
          <Input.TextArea
            showCount
            value={comment}
            placeholder={t(
              customPlaceholder || 'timetracker.comments.typeComment'
            )}
            disabled={disableInput}
            maxLength={MAX_COMMENT_LENGTH}
            autoSize={AUTO_SIZE_CONFIG}
            onChange={onChangeInputValue}
          />
        </Form.Item>
        {sendButton && !formComment && (
          <ButtonWrapper>
            {cancelButton && (
              <Button type="default" onClick={handleCancel}>
                {t('common.cancel')}
              </Button>
            )}
            <Button
              loading={isSubmitting}
              type="primary"
              onClick={handleClick}
              disabled={comment.trim().length < MIN_CHARS || disableInput}
            >
              {t('common.send')}
            </Button>
          </ButtonWrapper>
        )}
      </Form>
    </>
  );
};

AddComment.defaultProps = {
  setDisabledSendButton: undefined,
  canAddComment: false,
  sendButton: true,
  disableInput: false,
  saveComment: true,
  clearComment: false,
  required: true,
  formComment: false,
  customPlaceholder: '',
  onChangeTypeComment: undefined,
  handleCancel: undefined,
  cancelButton: false,
  formInstance: undefined,
};

export default AddComment;
