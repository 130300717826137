import styled from 'styled-components';
import { Button } from 'antd';

export const ButtonStyled = styled(Button)<{
  width?: string;
  height?: string;
  margin?: string;
  size?: string;
}>`
  ${({ width }) => (width ? `width: ${width};` : '')}
  ${({ height }) => (height ? `height: ${height};` : '')}
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  ${({ size }) => (size ? `size: ${size};` : '')}

  border: 0 !important;
  border-radius: ${({ theme }) => theme.shape.generalBorderRadius};
  margin-right: 6px;

  ${({ type, theme, disabled }) => {
    if (type === 'primary') {
      return `box-shadow: 0px 2px 0 ${theme.colors.boxShadow};
      color: ${theme.colors.white} !important;
      background-color: ${
        disabled
          ? `${theme.colors.primaryDisabled} !important`
          : theme.colors.links
      };`;
    }
    if (type === 'default' && !disabled) {
      return `box-shadow: 0px 2px 0 ${theme.colors.boxShadow};
        border: 1px solid ${theme.colors.inputBorder} !important;
        border-radius: ${theme.shape.generalBorderRadius};
        :hover {
          border: 1px solid ${theme.colors.links} !important;
        }`;
    }
    if (type === 'default' && disabled) {
      return `box-shadow: 0px 2px 0 ${theme.colors.grayModalBackground};
        color: ${theme.colors.white} !important;
        `;
    }
    return '';
  }}
`;
