import { FormInstance } from 'components/Form';
import { FormItemConfig } from 'components/Form/helpers';

export interface FormFields {
  [key: string]: string | boolean | null | undefined | moment.Moment | number;
}

export interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

export interface ActionsRow {
  id: number;
  rowValues: {
    id?: string;
    name: string;
    country?: string;
    alignment: string;
    padding: boolean;
    render?(value: any, { ...rest }?): any;
    value: any;
  }[];
}

export interface Options<ValueType = string> {
  value: ValueType;
  name: string;
}

export interface InputConfig {
  name: string;
  type: string;
  rules?: any[];
  value?: string;
  placeholder: string;
  options?: Options[];
  disabled?: boolean;
  optional?: boolean;
}

export interface redirectToPathConfig {
  path: string;
  id: string;
}

export interface ActionsConfig {
  deleteConfig?: {
    modalTitle: string;
    deleteMessage: string;
    deleteHandler(id: number | string): void;
  };
  viewConfig?: {
    modalTitle: string;
    form: FormItemConfig[];
    formStateDefault?: { [key: string]: string };
    viewMessage: string;
    viewHandler(instance: any): void;
    numberOfColumns?: number;
  };
  editConfig?: {
    modalTitle: string;
    form: FormItemConfig[];
    formStateDefault: { [key: string]: string };
    editMessage: string;
    editHandler(id: number | string, payload: any): void;
    onFieldsChange?: () => void;
    onClose?: () => void;
    redirectToPath?: redirectToPathConfig;
    disableDateHandler?(current: any, form: FormInstance): boolean;
    confirmationHandler?: boolean;
    numberOfColumns?: number;
    isMainButtonDisabled?: boolean;
  };
  auxiliarConfig?: {
    validateFormHandler(payload: any): boolean;
  };
  alternativeConfig?: {
    modalTitle: string;
    form: FormItemConfig[];
    formStateDefault: { [key: string]: string };
    alternativeMessage: string;
    alternativeHandler(id: number | string, payload: any): void;
    redirectToPath?: redirectToPathConfig;
    disabledDateHandler?(current: any, form: FormInstance): boolean;
    confirmationHandler?: boolean;
    numberOfColumns?: number;
    requiredFields?: string[];
  };
}

export interface RefObject {
  showEditModal: (id: number | string, formFields: FieldData[]) => void;
  showViewModal: (id: number | string, formFields: FieldData[]) => void;
  showAlternativeModal: (id: number | string, formFields: FieldData[]) => void;
  showDeleteModal: (id: number | string) => void;
  clearFilters: () => void;
}

export const getFieldData = (instance: any): FieldData[] => {
  const fieldData: FieldData[] = [];
  // const noTypedPerson = getNoTypedPerson(person);
  Object.keys(instance).forEach((key) => {
    fieldData.push({ name: key, value: instance[key] });
  });
  return fieldData;
};

const SPAN_COL_FULL = 24;

export const createSpanForColumns = (
  numberOfColumns: number | undefined
): number => {
  const span = numberOfColumns
    ? SPAN_COL_FULL / numberOfColumns
    : SPAN_COL_FULL;
  return span;
};
