import React, { useCallback, useState } from 'react';
import { Button } from 'components/Button';
import { Radio, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/lib/table';
import { DivPagination } from 'components/Pagination/styles';
import Pagination from 'components/Pagination';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import DatePicker from 'components/DatePicker';
import moment from 'moment';
import { State, PaginationType, ACTIVE } from './helpers';
import {
  DatePickerStyled,
  DivFilter,
  InputStyled,
  RadioButtonGroupStyled,
  SearchOutlinedStyled,
} from './styles';

function resetFilter<Columns extends Record<string, any>>(props: {
  clearFilters: () => void;
  dataIndex: keyof Columns;
  filterState: State<Partial<Columns>>;
}) {
  const { clearFilters, dataIndex, filterState } = props;
  const [, setFilter] = filterState;

  clearFilters();
  setFilter((previous) => {
    const filter = { ...previous };
    delete filter[dataIndex];
    return filter;
  });
}

function handleSearch<
  Columns extends Record<string | number | symbol, any>
>(props: {
  selectedKey: string;
  confirm: (param?: any) => void;
  dataIndex: keyof Columns;
  filterState: State<Partial<Columns>>;
  paginationState: State<PaginationType>;
}) {
  const { selectedKey, confirm, dataIndex, filterState, paginationState } =
    props;
  const [, setFilter] = filterState;
  const [, setPagination] = paginationState;

  setFilter((previous) => {
    return { ...previous, [dataIndex]: selectedKey };
  });

  setPagination((prevState) => {
    return { ...prevState, page: 1 };
  });
  confirm();
}

function getColumnSearchProps<Columns extends Record<string, any>>(props: {
  dataIndex: keyof Columns;
  filterState: State<Partial<Columns>>;
  t: (key: string) => string;
  paginationState: State<PaginationType>;
  placeholder?: string;
}): ColumnType<Columns> {
  const { dataIndex, filterState, paginationState, placeholder, t } = props;

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const selectedKey = selectedKeys[0] ? selectedKeys[0].toString() : '';
      return (
        <DivFilter>
          <InputStyled
            placeholder={placeholder}
            value={selectedKey}
            onChange={(event) => {
              setSelectedKeys(event.target.value ? [event.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              handleSearch({
                selectedKey,
                confirm,
                dataIndex,
                filterState,
                paginationState,
              });
              confirm();
            }}
          />
          <Space>
            <Button
              onClick={() => {
                handleSearch({
                  selectedKey,
                  confirm,
                  dataIndex,
                  filterState,
                  paginationState,
                });
                confirm();
              }}
              icon={<SearchOutlinedStyled />}
              type="primary"
            >
              {t('common.search')}
            </Button>
            <Button
              type="link"
              onClick={() => {
                if (clearFilters)
                  resetFilter({ clearFilters, dataIndex, filterState });
                confirm({ closeDropdown: true });
              }}
            >
              {t('common.reset')}
            </Button>
          </Space>
        </DivFilter>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlinedStyled filtered={filtered ? ACTIVE : ''} />
    ),
    onFilter: (value: string | number | boolean, record: Columns): boolean => {
      if (typeof value === 'string') {
        const result = record[dataIndex];
        return !!result;
      }
      return false;
    },
    render: (text: string) => {
      return t(text);
    },
  };
}

function getColumnStatusSearchProps<
  Columns extends Record<string, any>
>(props: {
  dataIndex: string;
  filterState: State<Partial<Columns>>;
  paginationState: State<PaginationType>;
  t: (key: string) => string;
}): ColumnType<Columns> {
  const { dataIndex, filterState, paginationState, t } = props;

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const selectedKey = selectedKeys[0] ? selectedKeys[0].toString() : '';
      return (
        <DivFilter>
          <RadioButtonGroupStyled>
            <Radio.Group
              onChange={(event) => {
                setSelectedKeys([event.target.value]);
                confirm({ closeDropdown: false });
              }}
              value={selectedKey}
              buttonStyle="solid"
            >
              <Radio value="true">{t(`common.active`)}</Radio>
              <Radio value="false">{t(`common.inactive`)}</Radio>
            </Radio.Group>
          </RadioButtonGroupStyled>
          <Space>
            <Button
              onClick={() => {
                handleSearch({
                  selectedKey,
                  confirm,
                  dataIndex,
                  filterState,
                  paginationState,
                });
                confirm({ closeDropdown: true });
              }}
              icon={<SearchOutlinedStyled />}
              type="primary"
            >
              {t('common.search')}
            </Button>
            <Button
              type="link"
              onClick={() => {
                if (clearFilters)
                  resetFilter({ clearFilters, dataIndex, filterState });
                confirm({ closeDropdown: true });
              }}
            >
              {t('common.reset')}
            </Button>
          </Space>
        </DivFilter>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlinedStyled filtered={filtered ? 'active' : ''} />
    ),
  };
}

function getColumnDateSearchProps<Columns extends Record<string, any>>(props: {
  dataIndex: string;
  filterState: State<Partial<Columns>>;
  paginationState: State<PaginationType>;
  placeholder: string;
  t: (key: string) => string;
}): ColumnType<Columns> {
  const { dataIndex, filterState, paginationState, placeholder, t } = props;

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => {
      const selectedKey = selectedKeys[0] ? selectedKeys[0].toString() : '';
      return (
        <DivFilter>
          <DatePickerStyled>
            <DatePicker
              placeholder={placeholder}
              onChange={(_, dateString: string) => {
                setSelectedKeys([dateString]);
                confirm({ closeDropdown: false });
              }}
              value={selectedKey ? moment(selectedKey) : null}
              allowClear
            />
          </DatePickerStyled>
          <Space>
            <Button
              onClick={() => {
                handleSearch({
                  selectedKey,
                  confirm,
                  dataIndex,
                  filterState,
                  paginationState,
                });
                confirm({ closeDropdown: true });
              }}
              icon={<SearchOutlinedStyled />}
              type="primary"
            >
              {t('common.search')}
            </Button>
            <Button
              type="link"
              onClick={() => {
                if (clearFilters)
                  resetFilter({
                    clearFilters,
                    dataIndex,
                    filterState,
                  });
                confirm({ closeDropdown: true });
              }}
            >
              {t('common.reset')}
            </Button>
          </Space>
        </DivFilter>
      );
    },
    filterIcon: (filtered: boolean) => (
      <SearchOutlinedStyled filtered={filtered ? ACTIVE : ''} />
    ),
  };
}

export function useActionTable<Columns extends Record<string, any>>() {
  const { t } = useTranslation();
  const paginationState = useState<PaginationType>({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [pagination, setPagination] = paginationState;
  const filterState = useState<Partial<Columns>>({});
  const [filter] = filterState;

  const getTableRequestBody = useCallback(
    (props: { sortColumn: string }): DTO.GetPaginatedList<Columns> => {
      const { sortColumn } = props;

      return {
        page: pagination.page,
        pageSize: pagination.pageSize,
        sortColumn,
        sortDirection: 'asc',
        filter,
      };
    },
    [filter, pagination.page, pagination.pageSize]
  );

  const generateInputFilter = useCallback(
    (props: { dataIndex: keyof Columns; placeholder?: string }) => {
      const { dataIndex, placeholder } = props;

      return getColumnSearchProps({
        dataIndex,
        filterState,
        paginationState,
        placeholder,
        t,
      });
    },
    [filterState, paginationState, t]
  );

  const generateBooleanFilter = useCallback(
    (props: { dataIndex: string }) => {
      const { dataIndex } = props;

      return getColumnStatusSearchProps({
        dataIndex,
        filterState,
        paginationState,
        t,
      });
    },
    [filterState, paginationState, t]
  );

  const generateDateFilter = useCallback(
    (props: { dateIndex: string }) => {
      const { dateIndex } = props;

      return getColumnDateSearchProps({
        dataIndex: dateIndex,
        filterState,
        paginationState,
        placeholder: t('common.selectDate'),
        t,
      });
    },
    [filterState, paginationState, t]
  );

  const Paginator = useCallback(
    (props: { name: string }) => {
      const { name } = props;

      const onChange = (page: number, pageSize: number) => {
        setPagination({
          ...pagination,
          page,
          pageSize,
        });
      };

      const onShowSizeChange = (current: number, size: number) => {
        setPagination({
          ...pagination,
          page: current,
          pageSize: size,
        });
      };

      return (
        <DivPagination>
          <Pagination
            total={pagination.total}
            current={pagination.page}
            pageSize={pagination.pageSize}
            onChange={onChange}
            onShowSizeChange={onShowSizeChange}
            showSizeChanger
            showTotal={(_total, range) =>
              `${range[0]}-${range[1]} ${t(
                'timetracker.timesheet.ofTotalHours'
              )} ${_total} ${name}`
            }
          />
        </DivPagination>
      );
    },
    [pagination, setPagination, t]
  );

  return {
    paginationState,
    filterState,
    generateInputFilter,
    generateBooleanFilter,
    generateDateFilter,
    getTableRequestBody,
    Paginator,
  };
}
