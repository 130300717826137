import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Comment from 'components/Comments';
import List from 'components/List';
import CommentsAvatar from '../CommentsAvatar';
import { CommentItem } from '../../helpers';
import { CommentListContainer, StyledText } from './styles';
import { buildDateTime, buildHeader, INIT_COMMENTS_AMOUNT } from './helpers';

interface Props {
  comments: CommentItem[];
  loading: boolean;
  resetState: boolean;
  customLabels?: { singular: string; plural: string };
}

const CommentList = ({
  comments,
  loading,
  resetState,
  customLabels,
}: Props) => {
  const { t } = useTranslation();
  const [visibleComments, setVisibleComments] = useState<CommentItem[]>([]);
  const [showAllComments, setShowAllComments] = useState(false);

  useEffect(() => {
    if (resetState) {
      setShowAllComments(false);
      setVisibleComments(comments.slice(0, INIT_COMMENTS_AMOUNT));
    }
    const amount = showAllComments ? comments.length : INIT_COMMENTS_AMOUNT;
    setVisibleComments(comments.slice(0, amount));
  }, [comments, resetState, showAllComments]);

  const onLoadMore = useCallback(() => {
    setShowAllComments(true);

    setVisibleComments((prevComments) => {
      const newComments = [
        ...prevComments,
        ...comments.slice(prevComments.length),
      ];
      return newComments;
    });
  }, [comments]);

  const loadMore =
    !showAllComments && comments.length > INIT_COMMENTS_AMOUNT ? (
      <StyledText onClick={onLoadMore}>
        {t('timetracker.timeEntries.viewMoreComments')}
      </StyledText>
    ) : null;

  return (
    <CommentListContainer>
      <List
        dataSource={visibleComments}
        loading={loading}
        loadMore={loadMore}
        header={buildHeader(comments.length, t, customLabels)}
        itemLayout="horizontal"
        renderItem={(comment) => (
          <Comment
            author={comment.author}
            content={comment.content}
            datetime={buildDateTime(
              comment.datetime || comment.dateTime || '',
              t
            )}
            avatar={<CommentsAvatar />}
          />
        )}
      />
    </CommentListContainer>
  );
};

CommentList.defaultProps = {
  customLabels: undefined,
};

export default CommentList;
