import React from 'react';
import { getDateFormatted } from 'shared/utils/common.utils';

export const INIT_COMMENTS_AMOUNT = 2;
export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const buildDateTime = (datetime: string, t: Function): JSX.Element => {
  return <span>{getDateFormatted(datetime, t)}</span>;
};

export const buildHeader = (
  commentsAmount: number,
  t: Function,
  customLabels?: { singular: string; plural: string }
): string => {
  return `${commentsAmount} ${
    commentsAmount > 1
      ? customLabels?.plural || t('timetracker.comments.comments')
      : customLabels?.singular || t('timetracker.comments.comment')
  }`;
};
