import React, {
  FC,
  memo,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
  Ref,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'components/AntTable/index';
import { ColumnsType } from 'antd/lib/table/interface';
import Modal from 'components/Modal';
import { FormItemConfig } from 'components/Form/helpers';
import {
  ColStyled,
  FormItemStyled,
  FormSection,
  FormWrapperStyled,
  HrStyled,
} from 'features/Modules/Timetracker/Customers/CustomerForm/styles';
import Form, { FormInstance } from 'components/Form';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import DatePicker from 'components/DatePicker';
import PhoneInput from 'components/PhoneInput';
import Switch from 'components/Switch';
import { Row } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import {
  ActionsConfig,
  FieldData,
  RefObject,
  createSpanForColumns,
} from './helpers';
import { ButtonWrapper, SelectStyled, StyledText } from './styles';

interface Props {
  columns: ColumnsType<any>;
  data: any[];
  actionsConfig?: ActionsConfig;
  ref?: Ref<RefObject>;
  form?: FormInstance;
  isLoading?: boolean;
  scroll?: any;
}

const ActionsAntTable: FC<Props> = forwardRef((props, ref): JSX.Element => {
  const { t } = useTranslation();
  const {
    deleteConfig = {} as Record<string, any>,
    editConfig = {} as Record<string, any>,
    viewConfig = {} as Record<string, any>,
    alternativeConfig = {} as Record<string, any>,
  } = props.actionsConfig || {};
  const [fields, setFields] = useState<FieldData[]>([]);
  const [activeRow, setActiveRow] = useState<number | string>(0);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [activeForm, setActiveForm] = useState<string>('');
  const [alternativeModal, setAlternativeModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [tableKey, setTableKey] = useState<number>(0);

  const handleCancel = useCallback(() => {
    fields.forEach((field) => {
      const error = props.form?.getFieldError(field.name);
      if (!error?.length) {
        return;
      }
      props.form?.setFields([
        {
          name: field.name,
          errors: [],
        },
      ]);
    });
    props.form?.resetFields();
    setCancelModal(false);
    setEditModal(false);
    setAlternativeModal(false);
    setDeleteModal(false);
    setViewModal(false);
  }, [fields, props.form]);

  const showEditModal = useCallback(
    (id: number | string, formFields: FieldData[]) => {
      setFields(formFields);
      setActiveRow(id);
      setActiveForm('edit-form');
      setEditModal(true);
    },
    []
  );

  const showAlternativeModal = useCallback(
    (id: number | string, formFields: FieldData[]) => {
      setFields(formFields);
      setActiveRow(id);
      setActiveForm('alt-form');
      setAlternativeModal(true);
    },
    []
  );

  const showDeleteModal = useCallback((id: number | string) => {
    setActiveRow(id);
    setDeleteModal(true);
  }, []);

  const showConfirmationModal = useCallback(() => {
    setConfirmationModal(true);
  }, []);

  const showViewModal = useCallback(
    (id: number | string, formFields: FieldData[]) => {
      setFields(formFields);
      setActiveRow(id);
      setViewModal(true);
    },
    []
  );

  const clearFilters = useCallback(() => {
    setTableKey((tKey) => tKey + 1);
  }, []);

  useImperativeHandle(ref, () => ({
    showEditModal,
    showAlternativeModal,
    showDeleteModal,
    showViewModal,
    clearFilters,
  }));

  const editCustomerHandler = useCallback(
    (values) => {
      editConfig?.editHandler(activeRow, values);
      setActiveRow(0);
      setEditModal(false);
    },
    [activeRow, editConfig]
  );

  const editAlternativeHandler = useCallback(
    (values) => {
      alternativeConfig?.alternativeHandler(activeRow, values);
      setActiveRow(0);
      setAlternativeModal(false);
      props.form?.resetFields();
    },
    [activeRow, alternativeConfig, props.form]
  );

  const deleteCustomerHandler = useCallback(() => {
    deleteConfig?.deleteHandler(activeRow);
    setActiveRow(0);
    setDeleteModal(false);
  }, [activeRow, deleteConfig]);

  const disableDateHandler = useCallback(
    (current: any, disableDateFunc: any) => {
      if (!disableDateFunc) return false;
      return disableDateFunc(current, props.form);
    },
    [props.form]
  );

  const filterOptionHandler = useCallback(
    (inputText, optionText) =>
      optionText?.children.toLowerCase().indexOf(inputText.toLowerCase()) >= 0,
    []
  );

  const filterSortHandler = useCallback(
    (optionA, optionB) =>
      optionA.children
        .toLowerCase()
        .localeCompare(optionB.children.toLowerCase()),
    []
  );

  const onSwitchChange = useCallback((checked: boolean, fieldName: string) => {
    setFields((prevFields) => {
      const newFields = [...prevFields];
      const index = newFields.findIndex((field) => field.name === fieldName);
      newFields[index].value = checked;
      return newFields;
    });
  }, []);

  const setModalInput = useCallback(
    (input: FormItemConfig): JSX.Element => {
      if (!input) {
        return <></>;
      }
      if (input.type === 'section') {
        return <FormSection>{t(input.label)}</FormSection>;
      }
      if (input.type === 'select') {
        return (
          <Form.Item
            label={t(input.label)}
            key={`form-key-${input.name}`}
            name={input.name}
            rules={input.rules}
            extra={
              input.disabledArray?.find(
                (field) => field.projectId === activeRow
              )?.hasHoursReported ? (
                <StyledText>
                  {t('timetracker.projects.cantEditProjectArea')}
                </StyledText>
              ) : (
                ''
              )
            }
          >
            <SelectStyled
              $hasHoursReported={
                input.disabledArray
                  ? input.disabledArray?.find(
                      (field) => field.projectId === activeRow
                    )?.hasHoursReported
                  : false
              }
              showSearch
              optionFilterProp="children"
              key={`form-key-${input.name}`}
              placeholder={t(input.placeholder || '')}
              width="-webkit-fill-available"
              disabled={
                input.disabledArray
                  ? input.disabledArray?.find(
                      (field) => field.projectId === activeRow
                    )?.hasHoursReported
                  : input.disabled
              }
              filterOption={filterOptionHandler}
              filterSort={filterSortHandler}
              onChange={input.onChangeHandler}
              allowClear={input.allowClear ?? true}
            >
              {input.options?.map((option) => (
                <SelectStyled.Option
                  key={`option-key-${option.value}`}
                  value={option.value}
                >
                  {option.name}
                </SelectStyled.Option>
              ))}
            </SelectStyled>
          </Form.Item>
        );
      }

      if (input.type === 'date') {
        return (
          <Form.Item
            label={t(input.label)}
            key={`modal-key-${input.name}`}
            name={input.name}
            rules={input.rules}
          >
            <DatePicker
              key={`modal-key-${input.name}`}
              disabled={input.disabled}
              placeholder={t(input.placeholder || '')}
              disabledDate={
                input.dateConfig?.disabledDate
                  ? input.dateConfig.disabledDate
                  : (current) => disableDateHandler(current, input.disabledDate)
              }
              onChange={input.onDateChangeHandler || undefined}
            />
          </Form.Item>
        );
      }
      if (input.type === 'phone') {
        return (
          <Form.Item
            label={t(input.label)}
            key={`modal-key-${input.name}`}
            name={input.name}
            rules={input.rules}
          >
            <PhoneInput
              key={`modal-key-${input.name}`}
              disabled={input.disabled}
              placeholder={t(input.placeholder || '')}
            />
          </Form.Item>
        );
      }

      if (input.type === 'password') {
        return (
          <Form.Item
            label={t(input.label)}
            key={`modal-key-${input.name}`}
            name={input.name}
            rules={input.rules}
            dependencies={input.dependencies}
          >
            <Input.Password
              key={`modal-key-${input.name}`}
              iconRender={(visible) =>
                visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
              }
              disabled={input.disabled}
              placeholder={t(input.placeholder || '')}
            />
          </Form.Item>
        );
      }

      if (input.type === 'switch') {
        return (
          <Form.Item
            label={t(input.label)}
            key={`modal-key-${input.name}`}
            name={input.name}
            rules={input.rules}
          >
            <Switch
              key={`modal-key-${input.name}`}
              checked={
                input.switchConfig?.checked ??
                fields
                  .find((field) => field.name === input.name)
                  ?.value.toString() === 'true'
              }
              disabled={input.disabled}
              onChange={(checked) => onSwitchChange(checked, input.name)}
              checkedChildren={t(input.switchConfig?.checkedChildren || '')}
              unCheckedChildren={t(input.switchConfig?.unCheckedChildren || '')}
            />
          </Form.Item>
        );
      }

      return (
        <Form.Item
          label={t(input.label)}
          key={`form-key-${input.name}`}
          name={input.name}
          rules={input.rules}
        >
          <Input
            key={`form-key-${input.name}`}
            name={input.name}
            type={input.type}
            onChange={input.onChange}
            placeholder={t(input.placeholder || '')}
            disabled={input.disabled}
          />
        </Form.Item>
      );
    },
    [
      t,
      filterOptionHandler,
      filterSortHandler,
      activeRow,
      disableDateHandler,
      fields,
      onSwitchChange,
    ]
  );

  useEffect(() => {
    if (!editModal) editConfig?.onClose?.();
  }, [editConfig, editModal]);

  return (
    <>
      {deleteConfig && (
        <Modal
          title={t(deleteConfig.modalTitle)}
          open={deleteModal}
          cancelText={t('common.cancel')}
          onOk={deleteCustomerHandler}
          onCancel={handleCancel}
          okText={t('common.confirm')}
        >
          {t(deleteConfig.deleteMessage)}
        </Modal>
      )}
      {editConfig && (
        <Modal
          open={editModal}
          title={t(editConfig.modalTitle)}
          onCancel={() =>
            props.form?.isFieldsTouched()
              ? setCancelModal(true)
              : setEditModal(false)
          }
          footer={null}
        >
          <Form
            form={props.form}
            fields={fields}
            name="edit-form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 23 }}
            onFieldsChange={editConfig.onFieldsChange}
            onFinish={(values) => {
              setConfirmationModal(false);
              editCustomerHandler(values);
            }}
          >
            <FormWrapperStyled>
              <Row>
                {editConfig.form?.map((input: FormItemConfig) => (
                  <ColStyled
                    key={input.name}
                    span={createSpanForColumns(editConfig.numberOfColumns)}
                  >
                    {setModalInput(input)}
                  </ColStyled>
                ))}
              </Row>
            </FormWrapperStyled>
            <HrStyled />
            <FormItemStyled shouldUpdate>
              {() => (
                <ButtonWrapper>
                  <Button
                    type="default"
                    onClick={() =>
                      props.form?.isFieldsTouched()
                        ? setCancelModal(true)
                        : setEditModal(false)
                    }
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    type="primary"
                    {...(editConfig.confirmationHandler
                      ? { onClick: showConfirmationModal }
                      : { htmlType: 'submit' })}
                    disabled={
                      editConfig.isMainButtonDisabled ??
                      (!props.form?.isFieldsTouched() ||
                        !!props.form
                          ?.getFieldsError()
                          .filter(({ errors }) => errors.length).length)
                    }
                  >
                    {t('common.save')}
                  </Button>
                </ButtonWrapper>
              )}
            </FormItemStyled>
          </Form>
        </Modal>
      )}
      {alternativeConfig && (
        <Modal
          open={alternativeModal}
          title={t(alternativeConfig.modalTitle)}
          onCancel={() => setCancelModal(true)}
          footer={null}
        >
          <Form
            form={props.form}
            fields={fields}
            name="alt-form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 23 }}
            onFinish={(values) => {
              setConfirmationModal(false);
              editAlternativeHandler(values);
            }}
          >
            <FormWrapperStyled>
              <Row>
                {alternativeConfig.form?.map((input: FormItemConfig) => (
                  <ColStyled
                    key={input.name}
                    span={createSpanForColumns(
                      alternativeConfig.numberOfColumns
                    )}
                  >
                    {setModalInput(input)}
                  </ColStyled>
                ))}
              </Row>
            </FormWrapperStyled>
            <HrStyled />
            <FormItemStyled shouldUpdate>
              {() => (
                <ButtonWrapper>
                  <Button type="default" onClick={() => setCancelModal(true)}>
                    {t('common.cancel')}
                  </Button>
                  <Button
                    type="primary"
                    {...(alternativeConfig.confirmationHandler
                      ? { onClick: showConfirmationModal }
                      : { htmlType: 'submit' })}
                    disabled={
                      !props.form?.isFieldsTouched(
                        alternativeConfig.requiredFields,
                        true
                      ) ||
                      props.form
                        ?.getFieldsError()
                        .filter(({ errors }) => errors.length).length > 0
                    }
                  >
                    {t('common.save')}
                  </Button>
                </ButtonWrapper>
              )}
            </FormItemStyled>
          </Form>
        </Modal>
      )}

      {viewConfig && (
        <Modal
          title={t(viewConfig.modalTitle)}
          open={viewModal}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            name="view-form"
            fields={fields}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 23 }}
          >
            <FormWrapperStyled>
              <Row>
                {viewConfig.form?.map((input: FormItemConfig) => (
                  <ColStyled
                    key={input.name}
                    span={createSpanForColumns(viewConfig.numberOfColumns)}
                  >
                    {setModalInput(input)}
                  </ColStyled>
                ))}
              </Row>
            </FormWrapperStyled>
            <HrStyled />
            <FormItemStyled shouldUpdate>
              {() => (
                <ButtonWrapper>
                  <Button type="primary" onClick={handleCancel}>
                    {t('common.cancel')}
                  </Button>
                </ButtonWrapper>
              )}
            </FormItemStyled>
          </Form>
        </Modal>
      )}
      <Table
        columns={props.columns}
        dataSource={props.data}
        pagination={false}
        getPopupContainer={(Node) => Node.parentElement || document.body}
        loading={props.isLoading || false}
        scroll={props.scroll}
        key={tableKey}
      />
      {cancelModal && (
        <Modal
          open
          title={t('common.confirm')}
          okType="primary"
          okText={t('common.confirm')}
          cancelText={t('common.cancel')}
          centered
          onOk={handleCancel}
          onCancel={() => setCancelModal(false)}
        >
          {t('timetracker.projectDetails.cancelConfirmMessage')}
        </Modal>
      )}
      {confirmationModal && (
        <Modal
          open
          title={t('common.confirm')}
          okText={t('common.confirm')}
          cancelText={t('common.cancel')}
          centered
          onOk={editConfig?.onClose}
          okButtonProps={{
            form: activeForm,
            htmlType: 'submit',
          }}
          onCancel={() => setConfirmationModal(false)}
        >
          {/* To-Do: create param to receive custom msg */}
          {t('timetracker.projects.confirmSave')}
        </Modal>
      )}
    </>
  );
});

export default memo(ActionsAntTable);
