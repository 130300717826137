import styled from 'styled-components';
import { Table } from 'antd';

export const IconWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 30px;

  :hover {
    color: ${({ theme }) => theme.colors.links};
  }
`;

export const DivWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const TableStyled = styled(Table)`
  .ant-table-thead .ant-table-cell {
    background-color: ${({ theme }) => theme.colors.headerBackground};
  }
  .ant-dropdown {
    min-width: 240px !important;
    max-width: 240px !important;
    width: 240px !important;
    right: 0px !important;
  }
  .ant-table-content {
    min-height: 30rem;
  }
  .ant-empty.ant-empty-normal {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    min-height: 16rem;
  }
  .ant-table-column-title {
    min-width: max-content;
  }
`;
