import DatePicker from 'components/DatePicker';
import { Input } from 'components/Input';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { SelectProps, Typography } from 'antd';
import Select from 'components/Select';

export const PhoneInputStyled = styled(PhoneInput)`
  .form-control {
    width: 100% !important;
    height: 31px !important;
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.links};
  }
`;

export const StyledSpan = styled.span`
  margin-bottom: 20px;
`;

export const InputStyled = styled(Input)`
  width: 100%;
  margin: 1rem 0;
`;

export const StyledText = styled(Typography.Text)`
  font-size: 8.5px;
`;

export const DatePickerStyled = styled(DatePicker)`
  width: 100%;
  margin: 1rem 0;
`;

export const ButtonWrapper = styled.div`
  margin: 10px 0px 0px auto;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

interface HoursReportedProps {
  $hasHoursReported?: boolean;
}

export const SelectStyled = styled(Select)<SelectProps & HoursReportedProps>`
  .ant-select-selector {
    ${(props) => {
      if (props?.$hasHoursReported) {
        return `border-color: #FAAD14 !important`;
      }
      return '';
    }}
  }
`;
