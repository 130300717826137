import React from 'react';
import { AvatarProps } from 'antd';
import { AvatarStyled } from './styles';

const Avatar = ({ children, ...restProps }: AvatarProps) => {
  return (
    <>
      <AvatarStyled {...restProps}>{children}</AvatarStyled>
    </>
  );
};

export default Avatar;
