import styled from 'styled-components';
import { StyledButton } from 'components/Timetracker/Timesheet/styles';

export const RefreshButtonStyled = styled(StyledButton)<{
  width?: string;
  height?: string;
  margin?: string;
  size?: string;
}>`
  max-width: 45px;
  max-height: 45px;
  border-radius: 20%;
  box-shadow: 1px 1px 3px #888888;

  .anticon {
    font-weight: bold;
  }
`;
