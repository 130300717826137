import React from 'react';
import { CheckboxProps } from 'antd';
import { CheckboxStyled } from './styles';

const Checkbox = ({ children, ...restProps }: CheckboxProps) => {
  return (
    <>
      <CheckboxStyled {...restProps}>{children}</CheckboxStyled>
    </>
  );
};

export default Checkbox;
