import styled from 'styled-components';

export const CommentListContainer = styled.div`
  overflow-y: auto;
  max-height: 250px;
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.links};
  cursor: pointer;
`;
