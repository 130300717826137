import { Rule } from 'antd/lib/form';

export const MAX_COMMENT_LENGTH = 280;
export const MIN_ROWS = 3;
export const MAX_ROWS = 5;
export const MIN_CHARS = 10; // also defines disabled save button

export const AUTO_SIZE_CONFIG = { minRows: MIN_ROWS, maxRows: MAX_ROWS };

export const getTextAreaRules = (t: Function, required?: boolean): Rule[] => {
  const rules: Rule[] = [
    {
      min: MIN_CHARS,
      message: t('common.fieldMustHaveAtLeastXCharacters', {
        x: MIN_CHARS,
      }),
    },
  ];

  if (required) {
    rules.push({
      required: true,
      message: t('common.fieldIsRequired'),
    });
  }
  return rules;
};
